import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import Linkedin from "../../images/blog/Linkedin-13.svg";
import Twitter from "../../images/blog/Twitter-19.svg";
import Facebook from "../../images/blog/Facebook.svg";
import { useIntl } from "gatsby-plugin-react-intl";

const Article = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  max-width: 1440px;
  border: 2px solid #ececee;

  transition: width 1s;
  @media (max-width: 980px) {
    width: 85%;
  }
  @media (max-width: 375px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  margin: auto;
  // border-top: 1px solid #e1e1e1;

  padding: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Picture = styled(GatsbyImage)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
  margin: 16px;
  min-width: 175px;
  width: 175px;
  height: 175px;
  align-self: flex-start;
  @media (max-width: 600px) {
    margin-left: 0;
    margin-bottom: 32px;
  }
  img {
    z-index: 1;
    width: 105%;
    height: 100%;
  }
`;

const Author = styled(Link)`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 16px;

  font-size: 35px;
  line-height: 41px;
  color: #222222;
  text-decoration: none;
  width: 100%;
`;

const AuthorTitle = styled(Link)`
  width: 100%;
  flex-basis: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 8px;

  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-top: 4px;
`;

const Bio = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;

  color: #4f4f4f;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const Vector = styled(Link)`
  width: 32px;
  margin-right: 8px;
`;

const Info = styled.div`
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

function AuthorCard({ posts, author, bio }) {
  console.log("text", posts.document.data.linkedin);
  let linked = posts.document.data.linkedin;
  console.log(typeof linked);
  return (
    <Article>
      <Picture
        image={getImage(posts.document.data.image)}
        alt="thumbnailA"
      ></Picture>

      <Info>
        <AuthorTitle>{posts.document.data.title}</AuthorTitle>

        <Author to={`/blog/${posts.document.data.name}`}>{author}</Author>

        {bio && <Bio>{posts.document.data.shortbio.text} </Bio>}

        <Row>
          {posts.document.data.linkedin && (
            <Vector to={posts.document.data.linkedin}>
              <Linkedin />
            </Vector>
          )}

          {posts.document.data.twitter && (
            <Vector to={posts.document.data.twitter}>
              <Twitter />
            </Vector>
          )}
          {posts.document.data.facebook && (
            <Vector to={posts.document.data.facebook}>
              <Facebook />
            </Vector>
          )}
        </Row>
      </Info>
    </Article>
  );
}

export default AuthorCard;
