export const redDownloadBtn = {
  button: {
    padding: "13px 24px",
    minWidth: "129px",
    height: "42px",
    background: "#2c6491",
    borderRadius: "60px",
    hoverBackground: "#d40000",
  },
  text: {
    width: "max-content",
    height: "16px",
    fontFamily: "Open Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#ffffff",
    hovercolor: "#ffffff",
  },
};

export const transparentDownloadBtn = {
  button: {
    padding: "13px 24px",
    minWidth: "129px",
    height: "42px",
    borderRadius: "60px",
    border: "1px solid #2c6491",

    hoverBackground: "#efefef",
  },
  text: {
    border: "1px solid #2c6491",
    width: "max-content",
    height: "16px",
    fontFamily: "Open Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#2c6491",
    hovercolor: "#2c6491",
  },
};

export const whiteDownloadBtn = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    minWidth: "317px",
    width: "max-content",
    height: "51px",
    background: "#ffffff",
    borderRadius: "60px",
    cursor: "pointer",
    hoverBackground: "#efefef",
  },
  text: {
    width: "max-content",
    height: "27px",
    fontFamily: "Open Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "27px",
    textTransform: "uppercase",
    color: "#111111",
  },
};

export const blueDownloadBtn = {
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    width: "auto",
    height: "51px",
    background: "#3571CE",
    borderRadius: "60px",
  },
  text: {
    width: "auto",
    height: "27px",
    fontFamily: "Open Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "27px",
    textTransform: "uppercase",
    color: "#ffffff",
    hovercolor: "#ffffff",
  },
};
