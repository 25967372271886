import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
// import Logo from "../../images/sharedImages/pdf_logo_dark.svg";
import Logo from "../../images/sharedImages/pdf_logo.svg";

import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { useIntl } from "gatsby-plugin-react-intl";
import Win7 from "../../images/sharedImages/win7.svg";
import Win10 from "../../images/sharedImages/win10.svg";
import Win11 from "../../images/sharedImages/win11.svg";
import { StaticImage } from "gatsby-plugin-image";

const FooterComponent = styled.footer`
  display: flex;
  justify-content: center;
  height: 160px;
  width: 100%;
  background: #222222;
  position: relative;
  margin: 0px;
  padding: 0px;
  z-index: ${({ navAcitve }) => (navAcitve ? "-2" : "10")};
  @media (max-width: 1060px) {
    height: max-content;
    padding: 36px 0px;
    align-items: center;
  }
  @media (min-width: 1060px) {
    .middle {
      margin: 0 32px;
    }
  }

  .logo {
    height: 31px;
    width: 164px;
    //you removed fill on the svg so you can use the same logo for header&footer
    fill: #ffffff;
  }

  .company-info {
    align-items: flex-start;
  }
`;

const PositionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 90%;
  height: 100%;

  @media (max-width: 1060px) {
    flex-direction: column;
    height: max-content;
    gap: 30px;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const Boxes = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.673077px;
  color: #ffffff;
  width: 100%;
  text-decoration: none;
  .PDFA {
    margin-left: 8px;
    width: max-content;
  }
  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    line-height: 18px;
    .PDFA {
      margin-top: 12px;
    }
  }
  .cls-3 {
    fill: #fff;
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.673077px;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    width: 100%;

    @media (max-width: 1060px) {
      font-size: 14px;
      line-height: 40px;
      color: #ffffff;
      text-align: left;
    }

    &:hover {
      font-weight: bold;
    }
  }

  //fix for width change on hover
  a::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  span {
    margin: 0px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;

    @media (max-width: 1200px) {
      margin: 0px 5px;
    }
    @media (max-width: 1060px) {
      display: none;
    }
  }
`;

const BoxesHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  @media (max-width: 1100px) {
    align-items: flex-start;
  }
`;

const WinIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;

  svg {
    width: 30px;
    height: 30px;
  }

  div {
    color: ${(props) => (props.color ? `${props.color}` : "gray")};
    text-align: center;
    font-weight: 100;
    font-size: 12.96px;
    line-height: 15px;
    margin-top: 2px;

    @media (max-width: 768px) {
      font-size: 9.3312px;
      line-height: 11px;
    }
  }
`;

function Footer() {
  const intl = useIntl();
  const { isMobileNavOpen, isMac, setMacOpen, setLinkType } =
    useContext(GlobalContext);
  return (
    <FooterComponent navAcitve={isMobileNavOpen}>
      <PositionContainer>
        <BoxesHolder className="company-info">
          <Boxes>
            <Logo className="logo" />
          </Boxes>
          <Boxes>
            PDF Pro Software Inc.<br></br>
            <br />
          </Boxes>
          <Boxes style={{ alignItems: "flex-start" }}>
            {intl.formatMessage({ id: "MemberOf" })}
            <Link
              className="PDFA"
              to="https://www.pdfa.org/member/pdf-pro-software-inc"
              style={{ marginTop: "bottom" }}
            >
              <StaticImage
                src="../../images/PDFA-white.png"
                quality={95}
                width={64}
              />
            </Link>
          </Boxes>
        </BoxesHolder>
        <BoxesHolder className="middle">
          <Boxes>
            <Link to={`/${intl.formatMessage({ id: "UrlFeatures" })}/`}>
              {intl.formatMessage({
                id: "Features",
              })}{" "}
            </Link>{" "}
            <span>|</span>
            <Link to={`/${intl.formatMessage({ id: "UrlSupport" })}/`}>
              {intl.formatMessage({
                id: "Support",
              })}
            </Link>
            <span>|</span>
            <Link to={`/${intl.formatMessage({ id: "UrlCompare" })}/`}>
              {intl.formatMessage({
                id: "Comparison",
              })}
            </Link>
            <span>|</span>
            {!isMac ? (
              <Link
                to={`${intl.formatMessage({
                  id: "BuyLink",
                })}/`}
                className="tracker"
              >
                {intl.formatMessage({
                  id: "Buy",
                })}
              </Link>
            ) : (
              <Link>
                <div
                  onClick={() => {
                    setLinkType("buy");
                    setMacOpen(true);
                  }}
                >
                  {intl.formatMessage({
                    id: "Buy",
                  })}
                </div>
              </Link>
            )}
          </Boxes>
          <Boxes>
            <Link to={`/${intl.formatMessage({ id: "UrlAbout" })}/`}>
              {intl.formatMessage({
                id: "AboutUs",
              })}
            </Link>
            <span>|</span>
            <Link
              title="Cookies Policy"
              to={`/${intl.formatMessage({
                id: "UrlInstructionsInstallation",
              })}/`}
            >
              {intl.formatMessage({
                id: "Installation&Activation",
              })}
            </Link>{" "}
            <span>|</span>
            <Link
              title="About Us"
              to={`/${intl.formatMessage({
                id: "UrlLegalities",
              })}/`}
            >
              {intl.formatMessage({
                id: "Legalities",
              })}
            </Link>{" "}
          </Boxes>
        </BoxesHolder>
        <BoxesHolder className="last">
          <Boxes>
            © 2011-{new Date().getFullYear()}, PDF Pro Software Inc. <br />
            {intl.formatMessage({
              id: "FooterRights",
            })}
          </Boxes>
          <Boxes>
            <div style={{ width: `100%`, textAlign: `left` }}>
              {intl.formatMessage({
                id: "CompatibleWith",
              })}
              :
            </div>
          </Boxes>
          <Boxes>
            <WinIcon color="#0078d4">
              <Win11 />
              <div>Windows 11</div>
            </WinIcon>
            <WinIcon color="#0079D7">
              <Win10 />
              <div>Windows 10</div>
            </WinIcon>
            <br />
            <WinIcon color="#6A6A6A">
              <Win7 />
              <div>Windows 7</div>
            </WinIcon>
          </Boxes>
        </BoxesHolder>
      </PositionContainer>
    </FooterComponent>
  );
}

export default Footer;
