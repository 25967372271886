import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useState, useEffect } from "react";

function SEO({
  description,
  lang,
  meta,
  title,
  schemaMarkup,
  altDefaultTitle,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            image
          }
        }
      }
    `
  );
  const image = site.siteMetadata.image;
  const keywords = site.siteMetadata.keywords;
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = altDefaultTitle; //|| site.siteMetadata?.title;

  const capitalizeFirstLetter = (text) => {
    return text[0]?.toUpperCase() + text?.slice(1, text.length);
  };

  schemaMarkup = schemaMarkup ? schemaMarkup : "";
  // console.log(schemaMarkup);
  const [scripts, setScripts] = useState(null);
  useEffect(() => {
    const parser = new DOMParser();
    let schemaArray = parser
      .parseFromString(schemaMarkup, "text/html")
      .querySelectorAll("script");
    let scr = [];
    schemaArray.forEach(
      (e, i) =>
        (scr[i] = (
          <script type="application/ld+json">{`${e.innerHTML}`}</script>
        ))
    );
    setScripts(scr);
  }, [schemaMarkup]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? `${capitalizeFirstLetter(title)}` : "PDF Creator"}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {scripts}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
