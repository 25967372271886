import React from "react";
import styled from "styled-components";
// import Logo from "../../images/sharedImages/logo_pdfcreator.png";
import HamburgerMenu from "../../images/sharedImages/hamburger_menu.svg";
import Close from "../../images/sharedImages/close_icon.svg";
import Logo from "../../images/sharedImages/pdf_logo.svg";
// import { Link } from "gatsby";
import { useState, useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import CustomDownloadButton from "./CustomDownloadButton";
import { redDownloadBtn } from "../../utils/CustomDownloadBtnStyles";

const HeaderComponent = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  @media (max-width: 968px) {
    height: ${({ showMenu }) => (showMenu ? "auto" : "70px")};
  }
  width: 100%;
  position: fixed;
  z-index: 10;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  /* padding: 0.3rem 0; */
  font-family: "Open Sans", sans-serif;

  .logo {
    height: 32px;
    width: 169px;
    //you removed fill on the svg so you can use the same logo for header&footer
    fill: #000000;
  }

  .hamburger-menu {
    display: none;
    height: 16px;
    width: 22px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;

    @media (max-width: 968px) {
      display: block;
    }
  }

  .close-icon {
    display: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;
    @media (max-width: 968px) {
      display: block;
    }
  }
`;

const PositionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 90%;
  height: 64px !important;
  min-height: 64px;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10;
`;

const Navbar = styled.nav`
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  padding-left: 0;
  height: 100%;

  ul {
    @media (max-width: 968px) {
      display: none;
    }
  }
  li {
    list-style: none;
    max-width: max-content;
    text-align: center;

    margin: 0px;
    padding: 0px;
    @media (max-width: 768px) {
      text-align: left;
      margin-left: 5%;
      &:nth-child(-n + 5) {
        transform: translateY(-64px);
      }
      &:nth-child(6) {
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
    }
    @media (max-height: 500px) {
      &:nth-child(-n + 3) {
        transform: translateY(-36px);
      }
    }

    a {
      height: 100%;
      text-align: right;
      width: ${({ id }) => (id === "buy" ? "250px" : "100%")};
      font-style: normal;
      font-weight: 300;
      text-decoration: none;
      line-height: 27px;
      font-size: 14px;
      color: #555;
      flex: none;
      order: 0;
      flex-grow: 0;
      box-sizing: border-box;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
      }

      &:hover {
        text-shadow: 0.5px 0px 0px #555, -0.5px 0px 0px #555;
        text-decoration: underline;
        text-underline-offset: 4.5px;
        @media (max-width: 768px) {
          text-decoration: none;
        }
      }
    }

    /* fix for width change on hover */
    a::before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    /* special class only when Link element is active */
    .active-link {
      font-weight: bold;
      text-decoration: underline;
      text-underline-offset: 4.5px;
      @media (max-width: 768px) {
        text-decoration: none;
      }
    }
  }
`;

const NavbarUnder = styled.nav`
  display: flex;
  align-items: center;
  padding-left: 0;
  height: 100%;
  width: 90%;
  margin: auto;
  padding-inline-start: 0 !important;
  padding: 16px 0;

  margin-bottom: 0;
  border-top: 2px solid #e6e6e6;

  @media (min-width: 968px) {
    display: none;
  }
  @media (max-width: 768px) {
    width: 95%;
    margin: 0;
    padding: 8px 0;
    padding-bottom: 16px;
  }

  li {
    list-style: none;
    max-width: max-content;
    text-align: center;

    margin: 0px;
    padding: 0px;
  }

  a {
    height: 100%;
    text-align: right;
    width: ${({ id }) => (id === "buy" ? "250px" : "100%")};
    font-style: normal;
    font-weight: 300;
    text-decoration: none;
    line-height: 27px;
    font-size: 14px;
    color: #555;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-sizing: border-box;
    width: 100%;

    &:hover {
      font-weight: bold;
      text-decoration: underline;
      text-underline-offset: 4.5px;
    }
  }

  /* fix for width change on hover */
  a::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  /* special class only when Link element is active */
  .active-link {
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 4.5px;
  }
`;

const Ul = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 15px;
  text-decoration: none;
  margin: 0px;
  @media (max-width: 968px) {
    padding-inline-start: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    li {
      text-align: left;
    }
    .button {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
    }
  }
`;

const Disclaimer = styled.div`
  color: darkgray;
  font-size: 12px;
  white-space: nowrap;
`;

const DisclaimerHolder = styled.div`
  position: relative;
  top: 24px;
  left: -168px;
  overflow: visible;
  max-width: 0;
`;

function Header({ data }) {
  const [showNav, setShowNav] = useState(false);
  const { setIsMobileNavOpen, isMac, setMacOpen, setLinkType } =
    useContext(GlobalContext);
  const intl = useIntl();

  function closeMenu() {
    setTimeout(() => {
      setShowNav(false);
      setIsMobileNavOpen(false);
    }, 300);
  }

  return (
    <HeaderComponent showMenu={showNav}>
      <PositionContainer showMenu={showNav}>
        <LogoBox>
          <Link to="/">
            {/* <Logo className="logo" onClick={closeMenu} /> */}
            {/* <StaticImage
              src="../../images/sharedImages/logo_pdfcreator.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF", "PNG"]}
              alt="Pdfcreator Logo"
            /> */}
            <Logo className="logo" onClick={closeMenu} />
          </Link>
          <DisclaimerHolder>
            <Disclaimer>
              {intl.formatMessage({ id: "LogoDisclaimer" })}
            </Disclaimer>
          </DisclaimerHolder>
        </LogoBox>
        <Navbar id="buy">
          {!showNav && (
            <HamburgerMenu
              className="hamburger-menu"
              onClick={() => {
                setShowNav(true);
                setIsMobileNavOpen(true);
              }}
            />
          )}
          {showNav && (
            <Close
              className="close-icon"
              onClick={() => {
                setShowNav(false);
                setIsMobileNavOpen(false);
              }}
            />
          )}
          <Ul show={showNav}>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlFeatures" })}/`}
                title="Features"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "Features" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlCompare" })}/`}
                title="Compare"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "Comparison" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlSupport" })}/`}
                title="Resources"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "Support" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlAbout" })}/`}
                title="Resources"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "AboutUs" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlBlog" })}/`}
                title="Resources"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "PraticalSheets" })}
              </Link>
            </li>
            <li>
              {!isMac ? (
                <CustomDownloadButton
                  title={intl.formatMessage({ id: "ToDownload" })}
                  navigation="https://track.pdfpro10.com/product/normal/pdfcreator/trial"
                  tracker="tracker"
                  styles={redDownloadBtn}
                />
              ) : (
                <CustomDownloadButton
                  title={intl.formatMessage({ id: "ToDownload" })}
                  navigation={"/"}
                  styles={redDownloadBtn}
                  onClick={() => {
                    setMacOpen(true);
                    setLinkType("trial");
                  }}
                />
              )}
            </li>
          </Ul>
        </Navbar>
      </PositionContainer>
      {showNav && (
        <NavbarUnder id="buy">
          <Ul show={showNav}>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlFeatures" })}/`}
                title="Features"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "Features" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlCompare" })}/`}
                title="Compare"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "Comparison" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlSupport" })}/`}
                title="Resources"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "Support" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlAbout" })}/`}
                title="Resources"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "AboutUs" })}
              </Link>
            </li>
            <li>
              <Link
                to={`/${intl.formatMessage({ id: "UrlBlog" })}/`}
                title="Resources"
                activeClassName="active-link"
                onClick={closeMenu}
              >
                {intl.formatMessage({ id: "PraticalSheets" })}
              </Link>
            </li>
            <li className="button">
              {!isMac ? (
                <CustomDownloadButton
                  title={intl.formatMessage({ id: "ToDownload" })}
                  navigation="https://track.pdfpro10.com/product/normal/pdfcreator/trial"
                  tracker="tracker"
                  styles={redDownloadBtn}
                />
              ) : (
                <CustomDownloadButton
                  title={intl.formatMessage({ id: "ToDownload" })}
                  navigation={"/"}
                  styles={redDownloadBtn}
                  onClick={() => {
                    setMacOpen(true);
                    setLinkType("trial");
                  }}
                />
              )}
            </li>
          </Ul>
        </NavbarUnder>
      )}
    </HeaderComponent>
  );
}

export default Header;
