import React, { useContext } from "react";
import styled from "styled-components";
import Main from "./styles/mainStyle";
import MainWrapper from "./styles/mainWrapperStyle";
import { BuyButton } from "./styles/buttonStyle";
import { useEffect, useState } from "react";
import Window from "../../images/blog/window.svg";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import { GlobalContext } from "../../Contexts/GlobalContext";

const CtaMain = styled(Main)`
  background: #e9eaec;
`;

const CtaWrapper = styled(MainWrapper)`
  flex-direction: column;
  max-width: 1440px;

  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 475px) {
    flex-wrap: wrap;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  color: #36454f;

  @media (max-width: 900px) {
    flex-basis: 100%;
    text-align: center;
  }

  @media (min-width: 900px) {
    width: 100%;
  }
`;

const Button = styled(BuyButton)`
  margin-top: 0;
  @media (max-width: 900px) {
    margin: auto;
    margin-top: 20px;
  }

  height: 75px;
  width: 315px;
  max-width: 315px;
  border-radius: 8px;
  box-shadow: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
`;
const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left; /* width: 100%; */
  div {
    margin-top: 8px;
    font-weight: 400;
    font-size: 20px;
    color: #36454f;
  }
  @media (max-width: 900px) {
    align-items: center;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Bottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  a {
    text-decoration: none;
  }
`;
function BlogCta() {
  const [setLpType] = useState("");
  const intl = useIntl();
  const { isMac, setMacOpen, setLinkType } = useContext(GlobalContext);

  useEffect(() => {
    if (window.location.href.includes("lp1")) setLpType("lp1");
    if (window.location.href.includes("lp2")) setLpType("lp2");
    if (window.location.href.includes("lp3")) setLpType("lp3");
  }, []);
  return (
    <CtaMain>
      <CtaWrapper>
        {/* <MainTitle>Try PDF Pro free for 15 days </MainTitle> */}
        <Bottom>
          <ButtonHolder>
            <Title>
              {intl.formatMessage({
                id: "Discover",
              })}{" "}
              PDF CREATOR
            </Title>
            <div>
              {intl.formatMessage({
                id: "WorkingWithPdfDocs",
              })}
            </div>
          </ButtonHolder>
          {!isMac ? (
            <Link
              tracker="tracker"
              to={intl.formatMessage({
                id: "BuyLink",
              })}
            >
              <Button tracker="tracker">
                {intl.formatMessage({
                  id: "ToDownload",
                })}
                <Window />
              </Button>
            </Link>
          ) : (
            <Link>
              <Button
                className="tracker"
                onClick={() => {
                  setLinkType("buy");
                  setMacOpen(true);
                }}
              >
                {intl.formatMessage({
                  id: "ToDownload",
                })}

                <Window />
              </Button>
            </Link>
          )}
        </Bottom>
      </CtaWrapper>
    </CtaMain>
  );
}

export default BlogCta;
