import styled from "styled-components"

const Main = styled.div`
  height: auto;
  background: #f5faff;
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default Main
