import React, { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../Contexts/GlobalContext";
import {
  redDownloadBtn,
  transparentDownloadBtn,
} from "../../utils/CustomDownloadBtnStyles";

const DownloadBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ styles }) => styles.padding};
  min-width: ${({ styles }) => styles.minWidth};
  width: "max-width";
  height: ${({ styles }) => styles.height};
  background: ${({ styles }) => styles.background};
  border-radius: ${({ styles }) => styles.borderRadius};
  cursor: pointer;

  &:hover {
    background: ${({ styles }) => styles.hoverBackground};
    text-decoration: none;
  }
`;

const Text = styled.div`
  display: "flex";
  justify-content: "center";
  align-items: "center";
  width: ${({ styles }) => styles.width};
  height: ${({ styles }) => styles.height};
  font-family: ${({ styles }) => styles.fontFamily};
  font-style: ${({ styles }) => styles.fontStyle};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize};
  line-height: ${({ styles }) => styles.lineHeight};
  text-transform: ${({ styles }) => styles.textTransform};
  color: ${({ styles }) => styles.color};

  &:hover {
    color: ${({ styles }) => styles.hoverColor};
  }
`;

function CustomDownloadButton({
  tracker,
  navigation,
  title,
  styles,
  target,
  onClick,
  hide,
}) {
  const { urlParameter, setLinkType } = useContext(GlobalContext);
  const queryString =
    typeof window !== "undefined" ? window.location.search : "";
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  // making sure that we send params to the database - in case that user doesn't directly go to download button
  // this way we make sure we attach params when user click on download button
  function addParametersToUrl() {
    if (urlParameter !== "" && queryString.length === 0) {
      window.history.pushState(
        { path: url + "?" + urlParameter },
        "",
        url + "?" + urlParameter
      );
    }
  }

  const hrefProp = navigation !== "/" ? { href: navigation } : { as: "div" };

  console.log(styles);
  return (
    <DownloadBtn
      as="a"
      className={tracker}
      style={{
        textDecoration: `none`,
        border: styles?.button?.border ? "1px solid #2c6491" : "",
        display: hide ? "none" : "",
      }}
      onClick={() => {
        // addParametersToUrl();
        onClick && onClick(true);
        // GA3 Event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "DownloadClick",
          category: "download",
          action: "click",
          label: "download_trial",
        });
        // GA4 Event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "GA4 - DownloadClick",
          eventName: "Download",
          action: "User clicked on the download button",
        });
      }}
      styles={styles?.button ? styles?.button : redDownloadBtn.button}
      target={target}
      rel="noopener noreferrer"
      {...hrefProp}
    >
      <Text styles={styles?.text || redDownloadBtn.text}>{title}</Text>
    </DownloadBtn>
  );
}

export default CustomDownloadButton;
