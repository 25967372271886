//This is global layout for the whole site
import React, { useEffect, useContext, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import Helmet from "react-helmet";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import MacPopup from "./MacPopup/macPopup";
import HeaderCheckout from "./headerCheckout";

//Global CSS style - usually you can have it as separate file
//Add fonts here to apply them globally

//trigger rebuild

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans"),
    url(../fonts/Work_Sans/static/WorkSans-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-Thin.ttf");
  font-style: normal;
  font-weight: 100;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-ExtraLight.ttf");
  font-style: normal;
  font-weight: 200;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
  font-display: block;
}

@font-face {
  font-family: "Work Sans";
  src: url("/fonts/Work_Sans/static/WorkSans-Black.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: block;
}


  html,body {
    margin: 0;
    padding: 0;
    //width has to be 100% and not 100vw because if it's 100vw then you will get horizontal scroll when you have vertical scroll
    //because vw will include scrollbar measurements
    width:100%;
    box-sizing:border-box;
    scroll-behavior: smooth;

    overflow:${({ navActive }) => (navActive ? "hidden" : "visible")};

    @media(max-width:330px){
      overflow-x:hidden;
    }
  }

  *{
    font-family: "Open Sans", sans-serif;
    //this could be problematic with some desings but it shouldn't be if you do it properly
    box-sizing:border-box;
  }

`;

//header has constant height through different viewports but we use position fixed and we need this pseudo container to fill the header space
//height is header height + border height
const HeaderPseudo = styled.div`
  height: 70px;
  width: 100%;
`;

//global container style that takes care of element alignment

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
  flex-grow: 1;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function Layout({ children, checkout }) {
  const { urlParameter, setUrlParameter, isMobileNavOpen } =
    useContext(GlobalContext);
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  const query = typeof window !== "undefined" ? window.location.search : "";

  useEffect(() => {
    const queryString = window.location.search;
    let urlParams = "";
    if (urlParameter === "" && queryString.length > 2) {
      urlParams = new URLSearchParams(queryString);
      setUrlParameter(urlParams.toString());
    } else {
      return;
    }
  }, [url, urlParameter, setUrlParameter, query]);

  const intl = useIntl();

  // const check = document.getElementsByClassName(
  //   "header__HeaderComponent-cUXcww ljujiz"
  // );
  // useLayoutEffect(() => {
  //   if (check) {
  //     if (check.length > 1) {
  //       check[1].remove();
  //     }
  //   }
  // }, [check.length]);

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://track.pdfpro10.com/static/tracking.js"
          async
        ></script>
      </Helmet>
      <GlobalStyle navActive={isMobileNavOpen} />
      <Wrapper>
        <MacPopup />

        {!checkout && <Header />}
        {checkout && <HeaderCheckout />}
        <HeaderPseudo />
        <Container>{children}</Container>
        <Footer />
      </Wrapper>
    </>
  );
}

export default Layout;
