import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Seo from "../components/shared/seo";
import AuthorCard from "../components/blog/authorCard";
import { Breadcrumbs } from "../components/blog/breadcrumbs";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import BlogCard from "../components/blog/blogCard";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import ShareButtons from "../components/blog/sharebuttons";
import Layout from "../components/shared/layout";
import { useState, useEffect } from "react";
import ContentTable from "../components/blog/contentTable";
import { useIntl } from "gatsby-plugin-react-intl";
import TimeIcon from "../images/blog/time.svg";
import Recommendation from "../components/blog/recommendation";
import BlogCta from "../components/blog/cta";

const Blog = styled.div`
  display: flex;
  flex-direction: column;
  color: #111111;
  font-family: Work Sans;
  font-style: normal;

  .vector {
    width: 100px;
  }

  .body {
    a {
      cursor: pointer;
      color: #d5442d;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }

  .gatsby-image-wrapper img {
    margin: auto;
  }
`;
const Div = styled.div`
  text-align: left;
  margin-right: 5%;
  /* margin-left: auto; */
  margin: auto;
  width: 80%;
  max-width: 1440px;
  transition: width 1s;
  @media (max-width: 980px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 92%;
  }

  /* p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  } */
`;
const DivRelated = styled.div`
  text-align: left;
  margin: auto;
  width: 100%;
  max-width: 1800px;
`;

const Hero = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  padding: 1%;
  margin: 8px auto;
  img {
    max-height: 480px;
    max-width: 80%;
    width: auto !important;
    height: auto;
    margin: auto;
    object-fit: fill !important;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  gap: 5% 5%;

  a {
    text-decoration: none;
  }
  /* align-items: center;
  justify-content: center; */
`;

const RelatedBlogPosts = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #111111;
  margin: 50px 0;
`;

const Related = styled.div`
  background: rgba(236, 236, 238, 0.5);
  width: 100%;

  padding-bottom: 64px;
`;

const PublishDate = styled.div`
  width: 100%;
  text-align: left;
`;

const Author = styled(GatsbyImage)`
  width: 75px;
  height: 75px;
  border-radius: 100%;
`;

const PublishAuthor = styled.div`
  width: 100%;
  text-align: left;
  a {
    color: #111111;
    text-decoration: none;
    font-weight: 800;
  }
  @media (max-width: 460px) {
    margin-bottom: 8px;
  }
`;

const CardPreview = styled.div`
  width: 90%;
  height: 1000px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: breath-animation;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  margin: 32px auto;
  h2 {
    width: 100%;
    text-align: center;
  }

  @keyframes breath-animation {
    0% {
      width: 580px;
    }

    25% {
      width: 580px;
    }

    75% {
      width: 280px;
    }

    100% {
      width: 280px;
    }
  }
`;

const SocialRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  margin-bottom: 64px;
  margin-top: 32px;
  @media (max-width: 1040px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .AuthorSection {
    display: flex;
    @media (max-width: 1040px) {
      flex-direction: column;
    }
    .DateAuthor {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media (max-width: 1040px) {
        margin-top: 32px;
        margin-left: 0px;
      }
    }
  }
`;

const Title = styled.h1`
  width: 80%;
  margin: 32px auto;
  text-align: left;
`;

const Time = styled.div`
  background: #f0f0f0;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  svg {
    margin-right: 8px;
  }
  @media (max-width: 1040px) {
    margin-top: 32px;
  }
`;

const Recommended = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1630px) {
    margin-top: 64px;
  }

  @media (max-width: 475px) {
    width: 90%;
  }
`;
const RecTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #36454f;
  margin-top: 24px;
  max-width: 390px;
  text-align: left;
  width: 100%;
`;

const Line = styled.div`
  height: 1px;
  background: #cdcdcd;
  width: 80%;
  margin: auto;
  margin-bottom: 64px;
  margin-top: 64px;
  @media (max-width: 980px) {
    width: 90%;
  }
`;
const RecImage = styled(GatsbyImage)`
  width: 390px;
  height: 250px;
  background: white;
  img {
    object-fit: ${(props) => props.fit} !important;
  }

  @media (max-width: 475px) {
    width: 100%;
    height: 200px;
  }
`;

const BlogPost = ({ data, location }) => {
  console.log("data:", data);
  let { post } = data;
  let { prev } = data;
  let { next } = data;
  let { related1 } = data;
  let { related2 } = data;
  let { related3 } = data;

  let { site } = data;

  console.log(post?.nodes[0].data);
  post = post?.nodes[0].data;
  prev = prev?.nodes[0];
  next = next?.nodes[0];
  related1 = related1?.nodes[0];
  related2 = related2?.nodes[0];
  related3 = related3?.nodes[0];

  console.log("related", related1);

  if (!post) post = data.prismicPdfcreatorPost.data;

  const intl = useIntl();
  const url = site?.siteMetadata.siteUrl + location.pathname;
  const description = post.description ? post.description : "Blog post";
  const [tableContent, setTableContent] = useState();
  const [tableContentType, setTableContentType] = useState();

  function offsetAnchor() {
    let hash = decodeURI(window.location.hash.replace("#", ""));
    let target = document.getElementById(hash);
    console.log(hash, ":", target);

    if (target != null) {
      if (location.hash.length != 0) {
        window.scrollTo(window.scrollX, target.offsetTop - 100);
      }
    }
  }

  function CapitalizeNeededPdfInOldUrls(a) {
    return a
      .replace("signer-les-fichiers-pdf", "signer-les-fichiers-PDF")
      .replace(
        "fusionner-des-fichiers-en-pdf",
        "fusionner-des-fichiers-en-PDF"
      );
  }

  useEffect(() => {
    /// Popup logic
    const bEl = document.getElementById("#id");
    let content = [];
    let contentType = [];

    window.setTimeout(offsetAnchor, 1);

    for (var i = 0, l = bEl.children.length; i < l; i++) {
      if (
        bEl.children[i].nodeName == "H2" ||
        bEl.children[i].nodeName == "H3"
      ) {
        bEl.children[i].id = bEl.children[i].textContent;
        content.push(bEl.children[i].textContent);
        contentType.push(bEl.children[i].nodeName);
      }
    }
    setTableContent((t) => content);
    setTableContentType((t) => contentType);
  }, []);

  return (
    <Layout>
      <Breadcrumbs
        currentPath={["/"].concat(
          location.pathname.split("/").filter((name) => name !== "")
        )}
        customUrl={post?.title}
      />
      <Blog>
        <Seo
          title={post?.title}
          schemaMarkup={post?.schema}
          description={post?.description}
        />
        <Div>
          {data?.prismicPdfcreatorPost && (
            <CardPreview>
              <h2>Card Preview: </h2>
              <BlogCard
                post={{ data: post }}
                author={post.author.document.data.name}
                visible={"flex"}
                margin={"auto"}
              />
            </CardPreview>
          )}
          <Title>{post?.h1}</Title>
          <SocialRow>
            <div className="AuthorSection">
              <Author
                image={getImage(post.author.document.data.image)}
                alt="thumbnailA"
              />
              <div className="DateAuthor">
                <PublishAuthor>
                  <Link to={`/blog/${post.author.document?.data.name}`}>
                    {post.author.document?.data.name}
                  </Link>
                </PublishAuthor>
                <PublishDate>{post?.publish_date}</PublishDate>
              </div>
            </div>
            <Time>
              <TimeIcon /> <span>{post?.read_time}</span>
            </Time>
          </SocialRow>
          {post?.introduction?.html && (
            <Div
              className="body"
              dangerouslySetInnerHTML={{ __html: post?.introduction.html }}
            />
          )}
          <div style={{ display: `flex`, justifyContent: `center` }}>
            <Hero image={getImage(post?.hero)} alt={"hero"} />
          </div>
          {post?.teaser?.html && (
            <Div
              className="body"
              dangerouslySetInnerHTML={{ __html: post?.teaser.html }}
            />
          )}
          <ContentTable content={tableContent} contentType={tableContentType} />

          <Div
            id="#id"
            className="body"
            dangerouslySetInnerHTML={{ __html: post?.body.html }}
          />
          {post?.faq?.html && (
            <>
              <Line />
              <Div
                className="body"
                dangerouslySetInnerHTML={{ __html: post?.faq.html }}
              />
              <Line />
            </>
          )}
          {post?.recommendation?.length > 1 && post?.recommendation && (
            <Recommendation content={post?.recommendation} />
          )}
          <ShareButtons
            url={url}
            title={post.title}
            description={description}
          />
        </Div>
      </Blog>
      <AuthorCard
        posts={post?.author}
        author={post?.author.document.data.name}
        bio={true}
      />

      <Related>
        <RelatedBlogPosts>
          {intl.formatMessage({
            id: "RelatedBlogs",
          })}{" "}
        </RelatedBlogPosts>

        <DivRelated>
          <Flex>
            {related1 && (
              <Recommended
                to={
                  related1.data.url != "old"
                    ? `/${intl.formatMessage({
                        id: "Blog",
                      })}/${related1.data.category}/${
                        related1.data.url ? related1.data.url : related1.uid
                      }`
                    : `/${intl.formatMessage({
                        id: "Blog",
                      })}/${CapitalizeNeededPdfInOldUrls(related1.uid)}`
                }
              >
                <RecImage
                  image={getImage(related1.data.thumbnail)}
                  alt="thumbnail"
                  fit={related1.data.fit_image}
                />
                <RecTitle>{related1.data.title}</RecTitle>
              </Recommended>
            )}
            {related2 && (
              <Recommended
                to={
                  related2.data.url != "old"
                    ? `/${intl.formatMessage({
                        id: "Blog",
                      })}/${related2.data.category}/${
                        related2.data.url ? related2.data.url : related2.uid
                      }`
                    : `/${intl.formatMessage({
                        id: "Blog",
                      })}/${CapitalizeNeededPdfInOldUrls(related2.uid)}`
                }
              >
                <RecImage
                  image={getImage(related2.data.thumbnail)}
                  alt="thumbnail"
                  fit={related2.data.fit_image}
                />
                <RecTitle>{related2.data.title}</RecTitle>
              </Recommended>
            )}

            {related3 && (
              <Recommended
                to={
                  related3.data.url != "old"
                    ? `/${intl.formatMessage({
                        id: "Blog",
                      })}/${related3.data.category}/${
                        related3.data.url ? related3.data.url : related3.uid
                      }`
                    : `/${intl.formatMessage({
                        id: "Blog",
                      })}/${CapitalizeNeededPdfInOldUrls(related3.uid)}`
                }
              >
                <RecImage
                  image={getImage(related3.data.thumbnail)}
                  alt="thumbnailA"
                  fit={related3.data.fit_image}
                />
                <RecTitle>{related3.data.title}</RecTitle>
              </Recommended>
            )}
          </Flex>
        </DivRelated>
      </Related>
      <BlogCta />
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery(
    $uid: String!
    $prev: String!
    $next: String!
    $related1: String!
    $related2: String!
    $related3: String!
  ) {
    post: allPrismicPdfcreatorPost(filter: { uid: { eq: $uid } }) {
      nodes {
        _previewable
        data {
          teaser {
            html
          }
          body {
            html
          }
          recommendation {
            link {
              html
            }
          }
          faq {
            html
          }
          introduction {
            html
          }
          h1
          schema
          title
          read_time
          publish_date(formatString: "MMMM D, Y")
          fit_image
          thumbnail {
            gatsbyImageData
          }
          description
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  facebook
                  image {
                    gatsbyImageData
                  }
                  linkedin
                  name
                  title
                  twitter
                  shortbio {
                    text
                  }
                }
              }
            }
          }
          hero {
            gatsbyImageData
          }
          title
        }
        uid
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    prev: allPrismicPdfcreatorPost(filter: { uid: { eq: $prev } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    next: allPrismicPdfcreatorPost(filter: { uid: { eq: $next } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related1: allPrismicPdfcreatorPost(filter: { uid: { eq: $related1 } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related2: allPrismicPdfcreatorPost(filter: { uid: { eq: $related2 } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
    related3: allPrismicPdfcreatorPost(filter: { uid: { eq: $related3 } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`;

export default withPrismicPreview(BlogPost);
