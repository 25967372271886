import React, { Suspense, useState, useEffect } from "react";
import styled from "styled-components";

const FlagStyle = styled.div`
  max-width: 48px;

  max-height: 32px;
  min-height: 32px;

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  min-width: 43px;
  max-height: 32px;
  min-height: 32px;
  background: #f2f2f2;
`;

const Flag = ({ tag }) => {
  const [FlagComponent, setFlagComponent] = useState(() => () => <Loading />);

  useEffect(() => {
    const importFlag = async () => {
      try {
        const { default: DynamicFlag } = await import(
          `../../../images/flags/${tag}.svg`
        );
        setFlagComponent(() => DynamicFlag);
      } catch (error) {
        // Handle the error or set a default flag
        console.error("Error loading flag:", error);
        setFlagComponent(() => () => <Loading />); // or a default flag component
      }
    };

    importFlag();
  }, [tag]);

  return (
    <FlagStyle>
      <Suspense fallback={<Loading />}>
        <FlagComponent />
      </Suspense>
    </FlagStyle>
  );
};

export default Flag;
