import React from "react"
import styled from "styled-components"
import { useEffect, useState } from "react"

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 64px auto;
  padding: 32px;
  overflow: hidden;
  background: #f3f3f3;
  transition: height 1s, opacity 0.5s, box-shadow 0.9s;
  margin-bottom: 14px;
`

const Content = styled.a`
  display: flex;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  margin: 4px 0;
  p {
    margin: 0;
  }
  a {
    display: block;
    color: #111111;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }
  .number {
    color: #111111;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-right: 8px;
  }

  :hover {
    a,
    .number {
      color: #e44331;
    }
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  margin-bottom: 24px;
  color: #36454f;

  p {
    margin: 0;
  }

  color: #36454f;
  :hover {
    cursor: pointer;
  }
`
function Recommendation({ content }) {
  return (
    <Table id="sticky">
      <Title
        id="title"
        dangerouslySetInnerHTML={{ __html: content[0]?.link.html }}
      ></Title>
      {content?.map((a, i) => {
        if (i != 0)
          return (
            <Content>
              <div className="number">{i}.</div>{" "}
              <div dangerouslySetInnerHTML={{ __html: a?.link.html }} />
            </Content>
          )
      })}
    </Table>
  )
}

export default Recommendation
