import styled from "styled-components"

export const BuyButton = styled.button`
  max-width: 230px;
  height: 80px;
  background: #00cd61;
  box-shadow: 0px 20px 12px -12px rgba(27, 223, 120, 0.316461);
  border-radius: 40px;
  border: none;
  color: white;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  letter-spacing: 0.96px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5%;
  }

  @media (max-width: 768px) {
    font-size: 14.4px;
    line-height: 18px;
    max-width: 184px;
    height: 64px;
  }

  width: 100%;
`

export const TryButton = styled.button`
  background: #32c5ff;
  box-shadow: 0px 20px 12px -12px rgba(50, 160, 255, 0.412533);
  border-radius: 30px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  padding: 16px 32px;
  border: none;
  color: #ffffff;
  max-height: 60px;
  width: auto;
  cursor: pointer;
  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5%;
  }
`
