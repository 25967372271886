import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";

const ArticleTitles = styled(Link)`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  width: 100%;
  color: #222222;
  padding: 8%;
  padding-bottom: 5%;
  padding-top: 0;
  height: 120px;
  text-decoration: none;
`;

const Article = styled.article`
  flex-grow: 1;
  flex-basis: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  max-width: 582px;
  ${(props) => (props?.margin ? `${props?.margin};` : `margin-bottom: 5%;`)}

  @media (max-width: 720px) {
    flex-basis: 100%;
  }
`;

const Button = styled.button`
  align-self: flex-start;
  border: none;

  background: #007de6;
  border-radius: 4px;
  border-radius: 4px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 10px;
  color: #ffffff;

  a {
    font-family: Work Sans;
    font-weight: 500;
    text-decoration: none;
  }

  :hover {
    background-color: red;
    cursor: pointer;
  }
  .link {
    color: White;
    font-weight: 500;
    font-size: 18px;
  }
`;

const DateField = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  /* identical to box height, or 193% */

  text-transform: uppercase;

  /* Gray 3 */

  color: #828282;
`;

const RowSpaceBtw = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 8%;
  padding-top: 5%;
  padding-bottom: 5%;
`;

const ReadTime = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: right;
  text-transform: uppercase;
  color: #222222;
`;
const Image = styled(GatsbyImage)`
  /* max-height: 200px; */
  width: 100%;
  height: 100%;
`;
const ImageFramer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 363px;
  background-blend-mode: multiply, normal;
  /* background: #f2f2f2; */
  background: white;
  border-radius: 8px 8px 0px 0px;
  border: solid rgba(0, 0, 0, 0.25) 1px;
  /* border-bottom: none; */
  overflow: hidden;
  cursor: pointer;
  img {
    ${(props) =>
      props.fit
        ? `object-fit: ${props.fit} !important;`
        : `object-fit: scale-down !important;`};
  }
`;

const Desc = styled.p`
  min-height: 110px;
  width: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  padding: 0 8%;
  margin: 0;
  height: 200px;
`;

const Author = styled(Link)`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #222222;
`;
function BlogCard({ post, author, visible, margin }) {
  function filterCategoryName(name) {
    name = name.replaceAll("-", " ");
    return name;
  }

  function CapitalizeNeededPdfInOldUrls(a) {
    return a
      .replace("signer-les-fichiers-pdf", "signer-les-fichiers-PDF")
      .replace(
        "fusionner-des-fichiers-en-pdf",
        "fusionner-des-fichiers-en-PDF"
      );
  }

  function ResolveBlogURL() {
    return `/${intl.formatMessage({
      id: "UrlBlog",
    })}${post.data.url != "old" ? "/" + post.data.category : ""}/${
      post.data.url
        ? post.data.url != "old"
          ? post.data.url
          : CapitalizeNeededPdfInOldUrls(post.uid)
        : post.uid
    }${post.data.url != "old" ? "/" : ""}`;
  }
  const intl = useIntl();
  // console.log(translateDate("January 15, 2022", intl.locale));

  function translateDate(date, language = "fr") {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const translatedDate = new Intl.DateTimeFormat(language, options)?.format(
      new Date(date)
    );
    return translatedDate;
  }

  return (
    <Article style={{ display: visible }} margin={margin}>
      {" "}
      <ImageFramer
        fit={post.data.fit_image}
        onClick={() => navigate(`${ResolveBlogURL()}`)}
      >
        <Image image={getImage(post.data.thumbnail)} alt="thumbnail" />
      </ImageFramer>
      <RowSpaceBtw>
        <Button style={{ backgroundColor: post.data.category_color }}>
          <Link
            className="link"
            to={`/${intl.formatMessage({
              id: "UrlBlog",
            })}/${post.data.category}/`}
          >
            {filterCategoryName(
              post.data.category.charAt(0).toUpperCase() +
                post.data.category.slice(1)
            )}
          </Link>
        </Button>
        <ReadTime>{post.data.read_time}</ReadTime>
      </RowSpaceBtw>{" "}
      <ArticleTitles to={ResolveBlogURL()}>{post.data.title}</ArticleTitles>
      <Desc>{post.data.description}</Desc>
      <RowSpaceBtw>
        <Author
          to={`/${intl.formatMessage({
            id: "UrlBlog",
          })}/${post.data.author.document.data.name}/?pick=-1`}
        >
          {intl.formatMessage({
            id: "By",
          })}{" "}
          <b>{post.data.author.document.data.name}</b>
        </Author>
        <DateField>
          {" "}
          {translateDate(post.data.publish_date, intl.locale)}
        </DateField>
        {/* translateDate("DECEMBER 22, 2022", intl.locale) */}
      </RowSpaceBtw>
    </Article>
  );
}

export default BlogCard;
