exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-dpa-js": () => import("./../../../src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-instructions-js": () => import("./../../../src/pages/installation-instructions.js" /* webpackChunkName: "component---src-pages-installation-instructions-js" */),
  "component---src-pages-installation-js": () => import("./../../../src/pages/installation.js" /* webpackChunkName: "component---src-pages-installation-js" */),
  "component---src-pages-installed-js": () => import("./../../../src/pages/installed.js" /* webpackChunkName: "component---src-pages-installed-js" */),
  "component---src-pages-legalities-js": () => import("./../../../src/pages/legalities.js" /* webpackChunkName: "component---src-pages-legalities-js" */),
  "component---src-pages-lp-1-installation-instructions-js": () => import("./../../../src/pages/lp1/installation-instructions.js" /* webpackChunkName: "component---src-pages-lp-1-installation-instructions-js" */),
  "component---src-pages-lp-1-terms-and-conditions-js": () => import("./../../../src/pages/lp1/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-lp-1-terms-and-conditions-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchased-jsx": () => import("./../../../src/pages/purchased.jsx" /* webpackChunkName: "component---src-pages-purchased-jsx" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-filter-author-js": () => import("./../../../src/templates/blog-filter-author.js" /* webpackChunkName: "component---src-templates-blog-filter-author-js" */),
  "component---src-templates-blog-filter-category-js": () => import("./../../../src/templates/blog-filter-category.js" /* webpackChunkName: "component---src-templates-blog-filter-category-js" */),
  "component---src-templates-blog-filter-featured-js": () => import("./../../../src/templates/blog-filter-featured.js" /* webpackChunkName: "component---src-templates-blog-filter-featured-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

