exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "pdfcreator_post") {
    return `/preview/blog/${doc.data?.category}/${
      doc.data?.url ? doc.data.url.replaceAll(" ", "-") : doc.uid
    }`;
  }

  // Backup for all other types
  return `/${doc.uid}`;
};
