import styled from "styled-components"

const MainWrapper = styled.div`
  width: 80%;
  height: 100%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;

  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 80%;
  }
`

export default MainWrapper
