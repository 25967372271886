import React from "react";
import Layout from "./src/components/shared/layout";
import ErrorBoundary from "./src/components/shared/errorBoundary";
import Helmet from "react-helmet";
import GlobalContextProvider from "./src/Contexts/GlobalContext";

import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

import { linkResolver } from "./src/utils/linkResolver";
import PageTemplate from "./src/templates/blogpost.js";
import { navigate } from "gatsby";

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log("location", location);
  console.log("location prev", prevLocation);

  if (prevLocation && !prevLocation.pathname.includes("purchased")) {
    const searchParams = new URLSearchParams(location.search);
    if ([...searchParams].length > 0) {
      console.log("searchParams", searchParams);
      navigate(`${prevLocation.pathname}?${searchParams}`, { replace: true });
    }
  }
};

//gatsby docs says that wrapRootElement is for providers
export const wrapRootElement = ({ element, props }) => {
  return (
    <ErrorBoundary>
      <GlobalContextProvider>
        <PrismicPreviewProvider
          repositoryConfigs={[
            {
              repositoryName: process.env.PRISMIC_REPOSITORY,
              linkResolver,
              componentResolver: componentResolverFromMap({
                pdfcreator_post: PageTemplate,
              }),
            },
          ]}
        >
          {element}
        </PrismicPreviewProvider>
      </GlobalContextProvider>
    </ErrorBoundary>
  );
};

//here are more detailed docs - https://dev.to/crock/gatsby-js-browser-apis-explained-wrappageelement-vs-wraprootelement-n4k

export const wrapPageElement = ({ element, props }) => {
  console.log("propovio", props);
  return (
    <ErrorBoundary>
      <Helmet>
        <script type="text/javascript">
          {`var baseurl = "pdfcreator.${process.env.LANGUAGE}";`}
        </script>
      </Helmet>
      {/* <Layout> */}
      {element}
      {/* </Layout> */}
    </ErrorBoundary>
  );
};
