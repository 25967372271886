import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Flag from "./Flag";
import { countryInfo } from "../../../helpers/PriceCalculator";
import DropdownLocation from "./DropdownLocation";
import { countryTagList } from "../../../helpers/CountryTags";

import useCheckout from "../../../Zustand/CheckoutStore";
import useLanguage from "../../../Zustand/LanguageStore";

const options = Object.entries(countryTagList).map(([value, label]) => ({
  value,
  label,
}));

const MainStyle = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;

  svg {
    min-width: 35px;
    min-height: 35px;
    transform: scaleX(1.2);
    translate: 0px 2px;
  }

  width: 105px;
  height: 40px;

  border-radius: 5px;
  border: 1px solid #cfe2ff;

  background: #fff;
  padding: 8px;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: max-content;
  overflow: visible;
  position: relative;
`;
const CountryText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: rgba(0, 0, 0, 0.6);
  margin-left: 12px;
  min-width: max-content;
  width: max-content;
  margin-right: 16px;
`;

function CountryDropdown({ setWidth }) {
  const [isOpen, setIsOpen] = useState(false);
  const { lockLanguage } = useCheckout();
  const { language, setLanguage } = useLanguage();

  const [info, setInfo] = useState(() => {
    let info = countryInfo();
    if (info?.countryTag === "US") {
      info.countryName = "United States";
    }
    return info;
  });

  const holder = useRef(null);

  useEffect(() => {
    if (setWidth) {
      setWidth(holder.current?.offsetWidth || 0);
    }
  }, []);

  useEffect(() => {
    let info = countryInfo();
    if (info?.countryTag === "US") {
      info.countryName = "United States";
    }
    setInfo(countryInfo());
    setLanguage(info?.countryTag);
  }, [language]);

  return (
    <MainWrapper>
      {language && (
        <MainStyle onClick={() => setIsOpen(!isOpen)}>
          <Flag tag={info?.countryTag} />
          <CountryText>{info?.countryTag}</CountryText>
        </MainStyle>
      )}
      {isOpen && !lockLanguage && (
        <DropdownLocation
          options={options}
          setOpen={setIsOpen}
          width={holder?.current?.offsetWidth || 0}
        />
      )}
    </MainWrapper>
  );
}

export default CountryDropdown;
